import React from 'react';
import styled from '@emotion/styled';
import LazyImage from '../components/lazy-image';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import linkStripper from '../utils/link-stripper';

//Asset Import
import leftAlignedPlaceholder from '../images/tmp/leftAlignedWhyReload.png';
import rightAlignedPlaceholder from '../images/tmp/rightAlignedWhyReload.png';
import Squiggle from '../components/squiggle';

const WhyReloadContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  font-family: DM Sans;
  overflow: hidden;
  @media (max-width: 1100px) {
    flex-wrap: wrap;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const LeftContent = styled.div`
  width: 70%;
  height: 450px;
  display: flex;
  position: relative;
  background-color: #e1e1eb;

  @media (max-width: 1280px) {
    width: 1700px;
  }
  @media (max-width: 1100px) {
    width: 100%;
    height: 275px;
    padding-top: 65px;
    padding-bottom: 75px;
  }
  @media (max-width: 620px) {
    height: 175px;
  }
`;

const RightContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1280px) {
    width: -webkit-fill-available;
  }
  @media (max-width: 1100px) {
    display: none;
  }
`;

const ImageUpperContainer = styled.div`
  position: absolute;
  top: -40px;
  left: 0;
`;

const ImageContainer = styled.div`
  width: 220px;
  height: 420px;
  @media (max-width: 620px) {
    width: 165px;
    height: 315px;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const LeftImage = styled.img`
  height: 100%;
  width: auto;
  margin-top: -15%;
`;

const RightImageContainer = styled.div`
  width: 540px;
  height: 420px;
  margin-top: 20%;
  @media (max-width: 735px) {
    margin-top: 0%;
    width: 100%;
  }
`;

const RightImage = styled.img`
  height: 100%;
  position: relative;
`;

const TextContent = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  @media (max-width: 1560px) {
    margin-left: 300px;
  }

  @media (max-width: 1380px) {
    margin-left: 240px;
  }
  @media (max-width: 735px) {
    width: 100%;
  }
  @media (max-width: 620px) {
    margin-left: 185px;
  }
  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

const TextContentInner = styled.div`
  @media (max-width: 735px) {
    width: 90%;
  }
`;

const Title = styled.h2`
  font-style: normal;
  font-family: DM Serif Text;
  color: #404040;
  font-weight: 400;
  font-size: 40px;
  line-height: 56px;
  color: #404040;
  max-width: 480px;
  @media (max-width: 750px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const TitleMob = styled.h2`
  color: #404040;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 56px;
  color: #404040;
  max-width: 480px;
  margin-bottom: 10px;
  @media (max-width: 750px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 40px;
  @media (max-width: 620px) {
    margin-top: 20px;
  }
`;

const SquiggleContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: -7.5vw;
  transform: rotate(90deg);
  mix-blend-mode: multiply;
  z-index: 2;
  @media (max-width: 735px) {
    display: none;
  }
`;

const WhyReloadContainerMobile = styled.div`
  display: none;
  @media (max-width: 500px) {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const LeftContentMob = styled.div`
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #f5f5f5;
`;

const RightContentMob = styled.div`
  width: 100%;
`;

const RightImageContainerMob = styled.div`
  width: 100%;
  height: 300px;
`;

const RectangleSplash = ({
  props,
  title,
  cta_text,
  cta_link,
  color_theme,
  left_image,
  right_image,

  cta_link_target,
}) => (
  <React.Fragment>
    <WhyReloadContainer>
      <LeftContent>
        <ImageUpperContainer>
          <ImageContainer>
            {left_image && <LazyImage {...left_image} />}
          </ImageContainer>
        </ImageUpperContainer>
        <TextContent>
          <TextContentInner>
            <Title>{title}</Title>
            <ButtonContainer>
              <ButtonNotUnderlined
                buttonText={cta_text}
                color={color_theme}
                arrowColor={color_theme}
                link={linkStripper(cta_link)}
                target={cta_link_target}
              />
            </ButtonContainer>
          </TextContentInner>
        </TextContent>
      </LeftContent>
      <RightContent>
        <RightImageContainer>
          {right_image && <LazyImage {...right_image} />}
        </RightImageContainer>
      </RightContent>
    </WhyReloadContainer>

    <WhyReloadContainerMobile>
      <LeftContentMob>
        <ImageUpperContainer>
          <ImageContainer>
            {left_image && <LazyImage {...left_image} />}
          </ImageContainer>
        </ImageUpperContainer>
        <TextContent>
          <TextContentInner>
            <TitleMob>{title}</TitleMob>
            <ButtonContainer>
              <ButtonNotUnderlined
                buttonText={cta_text}
                color={color_theme}
                arrowColor={color_theme}
                link={linkStripper(cta_link)}
                target={cta_link_target}
              />
            </ButtonContainer>
          </TextContentInner>
        </TextContent>
      </LeftContentMob>
      <RightContentMob>
        <RightImageContainerMob>
          {right_image && <LazyImage {...left_image} />}
        </RightImageContainerMob>
      </RightContentMob>
    </WhyReloadContainerMobile>
  </React.Fragment>
);

export default RectangleSplash;
